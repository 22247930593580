import "./App.scss";
import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import "./rest.css";
import {
  Routes,
  BrowserRouter,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import FormPage from "./pages/FormPage/FormPage";
import LoginPage from "./pages/LoginPage/LoginPage";
// import User from './store/user'
import StartPage from "./pages/startPage/StartPage";
import AdminPanelForms from "./pages/AdminPanelForms";
import RedactorForm from "./pages/RedactorForm";
import RedactorPoint from "./pages/RedactorPoint";
import { check } from "./http/controllers";
import { UserContext } from ".";
import Page404 from "./pages/Page404/Page404";
import Loading from "./components/loading/Loading";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import "animate.css/animate.min.css";
import { observer } from "mobx-react";
import { userStore } from "./store/user";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import { navigate } from "react-router-dom";
import ConnectionForm from "./pages/ConnectionForm/ConnectionForm";
import Header from "./components/header/Header";

const routes = [
  {
    path: "/",
    element: <StartPage />,
    errorElement: <>err</>,
  },
  {
    path: "/survey_form/:token",
    element: <FormPage />,
    errorElement: <>err</>,
  },
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <>err</>,
  },
];
const authRoutes = [
  {
    path: "/admin",
    element: <AdminPanelForms />,
    errorElement: <>err</>,
  },
  {
    path: "/redactorform",
    element: <RedactorForm />,
    errorElement: <>err</>,
  },
  {
    path: "/redactorpoint",
    element: <RedactorPoint />,
    errorElement: <>err</>,
  },
  {
    path: "/redactorpoint/:pointId",
    element: <RedactorPoint />,
    errorElement: <>err</>,
  },
  {
    path: "/form",
    element: <FormPage />,
    errorElement: <>err</>,
  },
  {
    path: "/changePassword",
    element: <ChangePassword />,
    errorElement: <>err</>,
  },
  {
    path: "/connectionForm",
    element: <ConnectionForm />,
    errorElement: <>err</>,
  },
];

const App = observer(() => {
  const user = useContext(UserContext);
  const [r, setR] = useState(true);
  const [l, setl] = useState(true);

  useEffect(() => {
    const body = document.getElementsByTagName("body");
    check()
      .then((data) => {
        user.setIsAuth(true);
        setR(!r);
        user.setRole(data.data.role);
      })
      .catch((e) => {
        user.setIsAuth(false);
        body[0].style.overflow = "hidden";
      })
      .finally(() => {
        setl(false);
        body[0].style.overflow = "unset";
      });
    //eslint-disable-next-line
  }, [user.isAdmin, user.isAuth]);

  useEffect(() => {
    const body = document.getElementsByTagName("body");
    // const footer = document.querySelector(".footer")
    if (l) {
      body[0].style.overflow = "hidden";
      // footer.style.bottom = "13vh"
    }
  }, []);

  return (
    <div className="App">
      <ReactNotifications />
      {l ? <Loading /> : ""}

      <UserContext.Provider value={userStore}>
        <BrowserRouter>
          <Routes>
            {routes.map((r, i) => (
              <Route key={i} path={r.path} element={r.element} />
            ))}
            {user.isAuth
              ? authRoutes.map((r, i) => (
                  <Route key={i} path={r.path} element={r.element} />
                ))
              : ""}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </div>
  );
});

export default App;
