import "../freeAnswer.scss";

const FreeAnswer = ({ title, currentQuestion, handleInputChange }) => {
  let idQuestion;
  try {
    idQuestion = currentQuestion.questions[0]
      ? currentQuestion.questions[0].id
      : -1;
  } catch (e) {
    idQuestion = -1;
    alert("Отсутсвуют question");
  }

  const checkTextArea = (e) => {
    const inputs = document.querySelectorAll(".form__check");
    const textArea = document.querySelector(`#question_${idQuestion}`);

    if (e[0] === "\n") {
      alert("Ответ не может начинатся с переноса строки");
      textArea.value = "";
    }

    if (
      e == " " ||
      e == "  " ||
      e == "   " ||
      e == "    " ||
      e == "     " ||
      e == "      " ||
      e == "       " ||
      e == "        " ||
      e == "         "
    ) {
      alert("Ответ не может состоять исключительно из пробельных символов");
      textArea.value = "";
    }

    if (e.length > 100) {
      textArea.value = textArea.value.substring(0, 96) + "...";
      alert("Длинна ответа не может быть больше 100 символов");
    }

    if (e.length > 0) {
      inputs.forEach((input) => {
        input.checked = false;
      });
    }
  };

  return (
    <>
      {/* <h2 className="form__question">{title}</h2> */}
      <h2 style={{ textAlign: "center" }}>{title}</h2>
      <div className="form__freeAnswerWrapp">
        <textarea
          onChange={(e) => checkTextArea(e.target.value)}
          id={`question_${idQuestion}`}
          className="form__freeAnswer"
          data-idquestion={idQuestion}
        ></textarea>
      </div>
    </>
  );
};

export default FreeAnswer;
