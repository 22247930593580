import Button from "../../components/buttons/Button";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { authorization } from "../../http/controllers";
import { useContext, useState } from "react";
import { UserContext } from "../..";
import { useNavigate } from "react-router-dom";
import "./loginPage.scss";
const LoginPage = () => {
  let user = useContext(UserContext);
  const navigate = useNavigate();
  let [isLogout, setIsLogout] = useState(false);

  const onSubmitL = (e) => {
    e.stopPropagation();
    // let form = new FormData(document.getElementById('login'))
    let form = {};
    form.username = document.getElementById("username").value;
    form.password = document.getElementById("password").value;

    authorization(form)
      .then((data) => {
        user.setIsAuth(true);
        navigate("/");
      })
      .catch((e) => {
        console.log("ERROR:", e.message);
        alert("Ошибка авторизации");
      });
  };
  const logout = () => {
    user.logout();
    setIsLogout(!isLogout);
    navigate("/");
  };

  return (
    <>
      <Header logout={logout} />
      <div className="container">
        <div key={"id"} className="formWrappLogin">
          <h2 className="form__question ">Авторизация</h2>
          <div className="loginForm">
            <input type="text" name="login" id="username" />
            <input type="password" name="password" id="password" />
            <Button
              onClick={onSubmitL}
              addClass="buttonForward"
              title="Войти"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default LoginPage;
