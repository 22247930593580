import axios from "axios";

axios.defaults.baseURL = "/api";
// axios.defaults.baseURL = process.env.REACT_APP_API

// для запросов без авторизации
const $host = axios.create({
  baseURL: process.env.REACT_APP_API,
});

// Для авторизованных запросов с headerauthorisation с токенами
const $authHost = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const authInterceptor = async (config) => {
  config.headers.authorization = `Token ${sessionStorage.getItem("token")}`;

  return config;
};

$authHost.interceptors.request.use(authInterceptor);

const authorization = async (form) => {
  const { data } = await $host.post("/token/", form);

  await sessionStorage.setItem("token", data.token);
  return true;
};

// const check = async () => {
//     const { data } = await $authHost.get('/check_token/');
//     console.log(data);
//     return { id: data.user_id }
// }

const check = async () => {
  const { data } = await $authHost.get("/check_token/");
  return { data };
};

// const downloadReport = async (id) => {
//     const { data } = await $authHost.get('/get_report/' + id + '/', {
//         responseType: 'blob'
//     });
//     return data
// }

const downloadReport = async (id, date) => {
  try {
    const { data } = await $authHost.post(
      `/get_report/${id}/`,
      {
        date: date,
      },
      {
        responseType: "blob",
      }
    );

    return data;
  } catch (error) {
    console.error("Ошибка при отправке отчета:", error);
  }
};

const getQuestionairAll = async (id) => {
  const { data } = await $host.get("/questionnaire/");
  return data;
};

const getQuestionairById = async (id) => {
  const { data } = await $host.get("/questionnaire/" + id + "/");
  return data;
};

// const sendDataForm = async (answers) => {
//   console.log("7777777777777", answers);
//   const { data } = await $host.post("/survey/", answers);
//   return data;
// };

const sendDataForm = async (answers, token) => {
  console.log("answers22222", answers);
  console.log("token22222", token);
  const { data } = await $host.post("/survey/", answers, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return data;
};

const getPointById = async (id) => {
  const { data } = await $authHost.get("/point/" + id + "/");
  return data;
};

const createNewForm = async (form) => {
  const { data } = await $authHost.post("/questionnaire/", form);
  return data;
};

const editForm = async (form, id) => {
  const { data } = await $authHost.put("/questionnaire/" + id + "/", form);
  return data;
};

// const createNewPoint = async (form) => {
//     const { data } = await $authHost.post('/point/', form);
//     return data;
// }

const createNewPoint = async (form, parentID) => {
  const requestBody = {
    parent: parentID,
    target: form,
  };

  const { data } = await $authHost.post("/point/", requestBody);
  return data;
};

const editPoint = async (form, id) => {
  const { data } = await $authHost.put("/point/" + id + "/", form);
  return data;
};

const deletePoint = async (id) => {
  const { data } = await $authHost.delete("/point/" + id + "/");
  return data;
};

const getLink = async (id) => {
  const { data } = await $authHost.get("/create_link/" + id + "/");
  return data;
};

const getCurrentForm = async (token) => {
  const { data } = await $host.get("/link/" + token + "/");
  return data;
};

// const createNewAltPoint = async (form) => {
//     const { data } = await $authHost.post('/alt_point/', form);
//     return data;
// }

const createNewAltPoint = async (form, parentID) => {
  const requestBody = {
    parent: parentID,
    target: form,
  };

  const { data } = await $authHost.post("/alt_point/", requestBody);
  return data;
};

const getQuestionairAllAuth = async (id) => {
  const { data } = await $authHost.get("/questionnaire/");
  return data;
};

const getQuestionairByIdAuth = async (id) => {
  const { data } = await $authHost.get("/questionnaire/" + id + "/");
  return data;
};

const getTypesQuestions = async () => {
  const { data } = await $host.get("/type_questions/");
  return data;
};

const changePasswordHttp = async ({ currentPassword, newPassword }) => {
  const requestBody = {
    new_password: newPassword,
    old_password: currentPassword,
  };
  const { data } = await $authHost.put("/changepassword/", requestBody);
  return true;
};

const getConnectionFormAuth = async () => {
  const { data } = await $authHost.get("/list_forms/");
  return data;
};

const getConfirmConnection = async (id) => {
  const { data } = await $authHost.get(`/add_form/${id}`);
  return data;
};

export {
  authorization,
  check,
  getQuestionairAll,
  getQuestionairById,
  downloadReport,
  sendDataForm,
  getPointById,
  createNewForm,
  editForm,
  createNewPoint,
  editPoint,
  deletePoint,
  getLink,
  getCurrentForm,
  createNewAltPoint,
  getQuestionairAllAuth,
  getQuestionairByIdAuth,
  getTypesQuestions,
  changePasswordHttp,
  getConnectionFormAuth,
  getConfirmConnection,
  // createAltPointConnectionForm
};
