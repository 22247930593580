//
//
//////////////////////// Mark //////////////////////////////
//
//
const NumberAnswer = ({ title, data, handleInputChange, currentQuestion }) => {
  const min_value = data[0].rules.min_value;
  const max_value = data[0].rules.max_value;
  const serverRegex = `/[^${min_value}-${max_value}]/g`;
  const regex = new RegExp(serverRegex.slice(1, -2)); // Убираем "/" и "g" из строки
  const validNumber = (e, regex) => {
    const value = e.target.value;
    const filteredValue = value.replace(regex, ""); // Используем регулярное выражение
    if (filteredValue.length > 1) {
      e.target.value = filteredValue.slice(0, 1); // Ограничиваем длину значения
    } else {
      e.target.value = filteredValue;
    }
  };

  return (
    <>
      <form id={"point" + data[0].id}>
        <div style={{ margin: "10px auto" }} className="form__number">
          <table>
            <tbody>
              {data.map((question, index) => {
                return (
                  <tr key={index}>
                    <td style={{ textAlign: "right" }}>{question.name}</td>
                    <td style={{ width: "100px" }}>
                      <input
                        id={`quetion_${question.id}`}
                        onChange={(e) => {
                          validNumber(e, regex);
                        }}
                        className="form__numberInput"
                        type="text"
                        name={`${question.id}`}
                        data-pointid={currentQuestion.id}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </form>
    </>
  );
};

export default NumberAnswer;
