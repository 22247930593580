import React from "react";
import RadioAnswer from "../radionAnswer/RadioAnswer";
import AdaptiveTable from "../adaptiveTable/AdaptiveTable";
import CheckboxAnswer from "../checkboxAnswer/CheckboxAnswer";
import FreeAnswer from "../freeAnswer/FreeAnswer";
import FreeAnswerAll from "../freeAnswer/freeAnswerAll/FreeAnswerAll";
import TextOrNon from "../textOrNon/TextOrNon";
import NumberAnswer from "../numberAnswer/NumberAnswer";
import CheckboxFreeAnswer from "../checkboxAndText/CheckboxAndText";
import RadioAndText from "../radioAndText/RadioAndText";
import NumberAnswerForAll from "../numberAnswer/numberAnswerForAll/NumberAnswerForAll";
import Button from "../../buttons/Button";

const FormView = ({
  questionnaire,
  currentQuestion,
  data,
  selectedRadioValue,
  handleRadioChange,
  handleInputChange,
  setFormData,
  goToNextQuestion,
  postData,
}) => {
  const submitAllHandler = (e) => {
    e.preventDefault();

    let answers = [];

    let numberInputs = document.querySelectorAll(".form__numberInput");
    let freeAnswers = document.querySelectorAll(".form__freeAnswer");

    let hasEmpty = false;
    /// СБОР ДАННЫХ ТЕКСТ ///
    freeAnswers.forEach((item) => {
      if (item.value === "") {
        hasEmpty = true;
      }
      answers.push({
        name: item.value,
        question: +item.dataset.idquestion,
      });
    });
    /// СБОР ДАННЫХ ЧИСЛА ///
    numberInputs.forEach((item) => {
      if (item.value === "") {
        hasEmpty = true;
      }
      answers.push({
        name: item.value,
        question: +item.name,
        pointId: +item.dataset.pointid,
      });
    });

    if (hasEmpty) {
      alert("Все поля должны быть заполнены.");
      return;
    }

    handleInputChange(answers, currentQuestion, setFormData);
    console.log("answer", answers);
    if (answers.length > 0 && !hasEmpty) {
      postData(answers);
      console.log("ОТПРАВЛЕНО");
    }
  };

  return (
    <section className="form">
      <h2 className="form__title">{questionnaire && questionnaire.name}</h2>
      {currentQuestion && (
        <div key={currentQuestion.id} className="formWrapp">
          {/* Отрисовка в зависимости от типа вопроса */}
          <p className="form__count">
            {data &&
              data.type_q !== false &&
              `${currentQuestion.number} / ${data.points.length}`}
          </p>
          {(() => {
            // Для отрисовки всех форм data.type_q = false
            if (data && data.type_q === false) {
              return (
                <div id="forms-container" className="forms-container">
                  <br />
                  {data.points.map((data1) => (
                    <div key={data1.id} className="form-wrapper">
                      {(() => {
                        switch (data1.type_point) {
                          case "text":
                            return (
                              <FreeAnswerAll
                                title={data1.name}
                                currentQuestion={data1}
                                handleInputChange={(e) =>
                                  handleInputChange(e, data1, setFormData)
                                }
                              />
                            );
                          case "mark":
                            // console.log("data1", data1);
                            return (
                              <NumberAnswerForAll
                                title={data1.name}
                                data={data1.questions ? data1.questions : data1}
                                handleInputChange={(e) =>
                                  handleInputChange(e, data1, setFormData)
                                }
                                currentQuestion={data1}
                              />
                            );

                          default:
                            return (
                              <p>Неизвестный тип вопроса: {data1.type_point}</p>
                            );
                        }
                      })()}
                    </div>
                  ))}
                  <Button
                    onClick={submitAllHandler}
                    addClass="buttonForward"
                    title="Отправить"
                  />
                </div>
              );
            }

            return (
              <div className="form-wrapper">
                {/* <h2>{currentQuestion.name}</h2> */}
                {(() => {
                  switch (currentQuestion.type_point) {
                    case "radio":
                      return (
                        <RadioAnswer
                          title={currentQuestion.name}
                          data={currentQuestion.questions}
                          selectedValue={selectedRadioValue}
                          handleRadioChange={handleRadioChange}
                          handleInputChange={(e) =>
                            handleInputChange(e, currentQuestion, setFormData)
                          }
                          currentQuestion={currentQuestion}
                          goToNextQuestion={goToNextQuestion}
                        />
                      );
                    case "raiting":
                      return (
                        <AdaptiveTable
                          title={currentQuestion.name}
                          data={currentQuestion.questions}
                          dataHeader={currentQuestion.headers}
                          handleInputChange={(e) =>
                            handleInputChange(e, currentQuestion, setFormData)
                          }
                          currentQuestion={currentQuestion}
                        />
                      );
                    case "check_box":
                      return (
                        <CheckboxAnswer
                          title={currentQuestion.name}
                          data={currentQuestion.questions}
                          handleInputChange={(e) =>
                            handleInputChange(e, currentQuestion, setFormData)
                          }
                        />
                      );
                    case "text":
                      return (
                        <FreeAnswer
                          title={currentQuestion.name}
                          currentQuestion={currentQuestion}
                          handleInputChange={(e) =>
                            handleInputChange(e, currentQuestion, setFormData)
                          }
                        />
                      );
                    case "text_or_non":
                      return (
                        <TextOrNon
                          title={currentQuestion.name}
                          data={currentQuestion.questions}
                          dataHeader={currentQuestion.headers}
                          handleInputChange={(e) =>
                            handleInputChange(e, currentQuestion, setFormData)
                          }
                        />
                      );
                    case "mark":
                      return (
                        <NumberAnswer
                          title={currentQuestion.name}
                          data={currentQuestion.questions}
                          handleInputChange={(e) =>
                            handleInputChange(e, currentQuestion, setFormData)
                          }
                          currentQuestion={currentQuestion}
                        />
                      );
                    case "ch_box&text":
                      return (
                        <CheckboxFreeAnswer
                          title={currentQuestion.name}
                          data={currentQuestion.questions}
                          handleInputChange={(e) =>
                            handleInputChange(e, currentQuestion, setFormData)
                          }
                          handleRadioChange={handleRadioChange}
                          currentQuestion={currentQuestion}
                        />
                      );
                    case "radio&text":
                      return (
                        <RadioAndText
                          title={currentQuestion.name}
                          data={currentQuestion.questions}
                          handleInputChange={(e) =>
                            handleInputChange(e, currentQuestion, setFormData)
                          }
                          handleRadioChange={handleRadioChange}
                          currentQuestion={currentQuestion}
                        />
                      );
                    case "department":
                      return (
                        <FreeAnswer
                          title={currentQuestion.name}
                          currentQuestion={currentQuestion}
                          handleInputChange={(e) =>
                            handleInputChange(e, currentQuestion, setFormData)
                          }
                        />
                      );
                    default:
                      return (
                        <p>
                          Неизвестный тип вопроса: {currentQuestion.type_point}
                        </p>
                      );
                  }
                })()}
              </div>
            );
          })()}
        </div>
      )}
    </section>
  );
};

export default FormView;
