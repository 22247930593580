import { useEffect } from "react";
import { getQuestionairByIdAuth } from "../http/controllers";

const useToken = (
  getCurrentForm,
  token,
  setQuestionare,
  setData,
  setCurrentQuestionId,
  getQuestionairById,
  currentForm,
  isTest
) => {
  useEffect(() => {
    // eslint-disable-next-line
    if (token) {
      getCurrentForm(token)
        .then((data) => {
          setQuestionare(data);
          if (data && data.points.length > 0) {
            setData(data);
            setCurrentQuestionId(data.points[0].id && data.points[0].id);
          }
        })
        .catch((err) => {
          alert("Данная ссылка больше не действительна");
        });
      //eslint-disable-next-line
    } else if (currentForm != -1) {
      if (!isTest)
        getQuestionairByIdAuth(currentForm)
          .then((data) => {
            setQuestionare(data);
            if (data && data.points.length > 0) {
              setData(data);
              setCurrentQuestionId(data.points[0].id && data.points[0].id);
            }
          })
          .catch((error) => {
            console.error(
              "There has been a problem with your fetch operation:",
              error
            );
          });
      else
        getQuestionairByIdAuth(currentForm)
          .then((data) => {
            setQuestionare(data);
            if (data && data.points.length > 0) {
              setData(data);
              setCurrentQuestionId(data.points[0].id && data.points[0].id);
            }
          })
          .catch((error) => {
            console.error(
              "There has been a problem with your fetch operation:",
              error
            );
          });
    }
    //eslint-disable-next-line
  }, [currentForm]);
};

export default useToken;
