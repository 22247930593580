import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../..";
import {
  check,
  downloadReport,
  getLink,
  getQuestionairAllAuth,
} from "../../http/controllers";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./startPage.scss";
import downloadDoc from "../../utils/downloadDoc";
import AdminButton from "../../components/AdminButton/AdminButton";
import { observer } from "mobx-react";

const StartPage = observer(() => {
  let user = useContext(UserContext);
  let [isLogout, setIsLogout] = useState(false);
  const [questionnaire, setQuestionare] = useState(null);
  const [linkHtml, setLinkHtml] = useState(<></>);
  const navigate = useNavigate();

  useEffect(() => {
    getQuestionairAllAuth()
      .then((data) => {
        setQuestionare(data);
      })
      .catch((error) => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  }, []);

  const logout = () => {
    user.logout();
    setIsLogout(!isLogout);
  };

  const sortById = (array) => {
    return array.sort((a, b) => a.id - b.id);
  };

  const sortedArray = questionnaire && sortById([...questionnaire]);

  const onGetLink = (e, id) => {
    if (id) {
      getLink(id).then((data) => {
        if (data.link) {
          let link = "http://" + window.location.host + data.link;

          let style = {
            top: e.pageY - 100 + "px",
            left: e.pageX - 260 + "px",
          };
          let linkHtml_ = (
            <div className="copyLink" style={style}>
              <input value={link} id="copyLink"></input>
              <div
                onClick={() => {
                  document.getElementById("copyLink").select();
                  document.execCommand("copy");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-copy"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                  />
                </svg>
              </div>
              <div
                onClick={() => {
                  setLinkHtml(<></>);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708" />
                </svg>
              </div>
            </div>
          );
          setLinkHtml(linkHtml_);
        } else console.log("ссылка пуста");
      });
    }
  };

  useEffect(() => {
    check()
      .then((data) => {
        sessionStorage.setItem("department", data.data.role);
      })
      .catch((error) => {
        console.error("Ошибка авторизации:", error);
      });
  }, []);

  const checkViewAdminButton =
    sessionStorage.getItem("department") !== "Союз МКСО";

  return (
    <>
      <Header logout={logout} />
      <section className="startPage">
        <h2 className="startPage__title">Список форм</h2>
        <div className="startPage__wrapp">
          <div className="startPage__adminButtonWrapp">
            {checkViewAdminButton && user.isAuth ? (
              <AdminButton
                onClick={(e) => {
                  navigate("/admin");
                }}
                classes={"startPage__adminPanel"}
                text={
                  <>
                    <div>Админ-панель</div>
                    <div>&#62;&#62;</div>
                  </>
                }
              />
            ) : null}
          </div>
          {user.isAuth &&
            sortedArray &&
            sortedArray.map((question, i) => {
              return (
                <div key={i} className="startPage__item">
                  <div className="startPage__hr"></div>
                  <div className="startPage__innerItem">
                    <p className="startPage__shortDescr">
                      {question.short_dscrpt}
                    </p>
                    <h3 className="startPage__name">{question.name}</h3>
                    <div className="btns__forms">
                      <div className="startPage__wrappToBeginCopy">
                        <button
                          title="Начать опрос"
                          onClick={() =>
                            navigate("/form", {
                              state: { formId: question.id },
                            })
                          }
                          className="startPage__button"
                        >
                          Начать
                        </button>
                        {user.isAuth ? (
                          <button
                            title="Скопировать ссылку"
                            id={`startPage__button${i}`}
                            className="startPage__button__download"
                            onClick={(e) => {
                              onGetLink(e, question.id);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              fill="currentColor"
                              className="bi bi-link-45deg"
                              viewBox="0 0 15.5 15.5"
                            >
                              <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z" />
                              <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z" />
                            </svg>
                          </button>
                        ) : (
                          ""
                        )}
                      </div>

                      {user.isAuth ? (
                        <div
                          title="Скачать отчет"
                          className="startPage__button__inputWrapp"
                        >
                          <input
                            className="startPage__button__date"
                            name="date"
                            default="null"
                            type="date"
                            // max="9999-12-31"
                            max="2031-12-31"
                            onChange={(e) => console.log(e.target.value)}
                          />
                          <button
                            className="startPage__button__inputDownload"
                            onClick={() => {
                              const date =
                                document.querySelector(
                                  'input[name="date"]'
                                ).value;
                              downloadDoc(question.id, downloadReport, date);
                            }}
                          >
                            Скачать отчет
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </section>
      <Footer />
      {linkHtml}
    </>
  );
});

export default StartPage;
